<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form ref="perForm" :model="formData" :rules="rules" label-width="200px">
        <!-- PDF封面 -->
        <el-form-item label="PDF封面" prop="cover">
          <el-upload v-if="title == '添加PDF信息'" :limit="1" :data="postData1" list-type="picture-card" ref="up1"
            show-file-list :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1"
            action="http://up-z0.qiniup.com" multiple :file-list="fileList" :on-remove="handleRemove1">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">(只能上传,宽:高=1.7:1)</div>
          </el-upload>
          <el-upload v-else :limit="1" :data="postData1" list-type="picture-card" ref="up1" show-file-list
            :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload1" action="http://up-z0.qiniup.com"
            multiple :file-list="fileList" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">(只能上传,宽:高=1.7:1)</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="标题名称" prop="title">
          <el-input v-model="formData.title" placeholder="请输入标题名称" style="width: 50%" />
        </el-form-item>

        <el-form-item label="分类类型" prop="typeId">
          <el-select v-model="formData.typeId" placeholder="请选择分类" @change="selectChange">
            <el-option v-for="(item, index) in options" :key="index" :label="item.typeName" :value="item.typeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="简介" prop="brief">
          <el-input v-model="formData.brief" type="textarea" placeholder="请输入简介(2-40个字)" style="width: 50%" />
        </el-form-item>
        <el-form-item label="专题" prop="topicIdArr">
          <el-select multiple v-model="formData.topicIdArr" placeholder="请选择专题" @change="selectChange1">
            <el-option v-for="(item, index) in res" :key="index" :label="item.topicName" :value="item.topicId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标签" prop="tags">
          <el-input v-model="formData.tags" placeholder="请输入标签名称(多个标签逗号分割)" style="width: 60%" @input="sc" />
        </el-form-item>

        <el-form-item label="上架状态" prop="upAction">
          <el-switch v-model="formData.upAction" active-text="定时上架" :active-value="1" inactive-text="默认不上架"
            :inactive-value="0" @change="upchange">
          </el-switch>
        </el-form-item>

        <el-form-item label="上架时间" prop="upDateMsg" v-if="formData.upAction == 1">
          <el-date-picker v-model="formData.upDateMsg" type="datetime" placeholder="请输入上架时间" style="width: 90%" format
            value-format="yyyy-MM-dd HH:mm" @change="change123">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="上传PDF文件" prop="pdfFid" v-if="!formData.pdfId">
          <el-upload class="upload-demo" action="http://up-z0.qiniup.com" ref="up" accept=".pdf"
            :on-preview="handlePreview" :on-success="handleAvatarSuccess" :data="postData"
            :before-upload="beforeAvatarUpload">
            <el-button type="primary">点击上传</el-button>
            <template #tip>
              <div class="el-upload__tip">只能上传pdf类型的文件</div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="formData.pdfId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="添加PDF分类" :visible="dialog" @close="btnCancel1">
      <!-- 表单 -->
      <el-form ref="pForm" :model="fdata" :rules="rules1" label-width="200px">
        <el-form-item label="标题名称" prop="typeName">
          <el-input v-model="fdata.typeName" placeholder="请输入标题名称" style="width: 50%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 100%">
            <el-select v-model="typeId" placeholder="请选择PDF分类" @change="selectChange2"
              style="wdith: 150px; margin-right: 10px">
              <el-option v-for="(item, index) in options" :key="index" :label="item.typeName" :value="item.typeId">
              </el-option>
            </el-select>
            <el-input v-model="searchtitle" placeholder="请输入标题名称" style="width: 150px"></el-input>
            <el-button type="primary" slot="append" @click="searchgetPDFInfoList" style="margin-left: 5px">查询
            </el-button>
          </div>
        </el-col>
        <el-col :span="12" align="right">
          <el-button class="el-icon-edit" type="warning" @click="addfl">管理PDF分类</el-button>
          <!-- <el-button class="el-icon-edit" type="success" @click="addf2"
            >管理PDF标签</el-button
          > -->
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo">添加PDF</el-button>
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->
    <el-button size="small" type="primary" @click="batchDelete" :disabled="albumFileIds.length === 0"
      style="margin-bottom: 20px; margin-left: 20px">批量删除</el-button>
    <div class="tab">
      <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
        ref="list" class="emp_table" v-if="list" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="cover" label="PDF封面" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img" v-if="scope.row.cover">
              <el-image style="width: 35%" :src="'http://file.innopinenut.com/' + scope.row.cover" :preview-src-list="[
                'http://file.innopinenut.com/' + scope.row.cover
              ]" />
            </div>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="title" label="标题" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="fileSize" label="文件大小" :show-overflow-tooltip="true" />

        <el-table-column align="center" prop="pdfToImgStatus" label="PDF转图片状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.pdfToImgStatus | spdf }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="totalPage" label="总页数" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center" prop="viewNum" label="阅读数" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center" prop="collectNum" label="收藏数量" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center" prop="downloadNum" label="下载次数" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center" prop="typeName" label="分类名称" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column align="center" prop="upAction" label="上架操作" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.upAction == 0 ? '默认不上架' : '定时上架' }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="upTime" label="上架时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.upTime | getLocalTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="upStatus" label="上下架状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="recStatus" label="推荐状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.recStatus == 0 ? '已推荐' : '默认不推荐' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="sort" label="排序" :show-overflow-tooltip="true" />
        <!-- <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column> -->

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="setPDFInfoStatus(scope.row)">
              {{ scope.row.upStatus == 1 ? '上架' : '下架' }}
            </el-button>

            <el-button type="text" size="small" @click="setPDFInfoRecStatus(scope.row)">
              {{ scope.row.recStatus == 1 ? '设置推荐' : '取消推荐' }}
            </el-button>
            <el-button type="text" size="small" @click="updateSchoolInfo(scope.row)">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="delPDFInfo(scope.row)">
              删除
            </el-button>

            <el-button type="text" size="small" @click="getView(scope.row)">
              浏览记录
            </el-button>
            <el-button type="text" size="small" @click="viewShare(scope.row)">
              分享记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.pageSize"
        @pagination="getPDFInfoList" />
    </div>
  </div>
</template>

<script>
import {
  getTopics,
  addType,
  getUploadToken,
  getTypes,
  getPDFInfoList,
  addPDFInfo,
  setPDFInfoStatus,
  modifyPDFInfo,
  delPDFInfo,
  delPDFInfos,
  setPDFInfoRecStatus
} from '../../request/http'

export default {
  data() {
    return {
      fileList: [],
      albumFileIds: [],
      dialog: false,
      fdata: {
        typeName: '',
        typeGroup: 'PDF'
      },
      typeId: '',
      title: '', //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: '', // 列表
      options: [],
      res: [],
      total: 0, //总条数
      postData: {
        token: '',
        key: '' //上传的Logo
      },
      queryParams: {
        page: 1,
        pageSize: 10
      },
      formData: {
        pdfFid: '',
        typeId: '',
        title: '',
        brief: '',
        topicIdArr: [],
        tags: '',
        upAction: 0,
        upDateMsg: '',
        cover: ''
      },
      searchtitle: '', //搜索名称
      rules: {
        title: [
          { required: true, message: '标题名称不能为空', trigger: 'blur' }
        ],

        pdfFid: [
          {
            required: true,
            message: '请上传符合pdf格式的文件',
            trigger: 'blur'
          }
        ],
        typeId: [
          { required: true, message: '分类Id不能为空', trigger: 'blur' }
        ],
        upAction: [
          { required: true, message: '定时发布不能为空', trigger: 'blur' }
        ],
        brief: [{ required: true, message: '简介不能为空', trigger: 'blur' }],
        sort: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
        upDateMsg: [
          { required: true, message: '上架时间不能为空', trigger: 'blur' }
        ]
      },
      rules1: {
        typeName: [
          { required: true, message: '分类名称不能为空', trigger: 'blur' }
        ]
      },
      imageUrl1: "",
      postData1: {
        token: "",
        key: "", //上传的Logo
      },
    }
  },
  created() {
    this.getPDFInfoList()
    this.getList()
    this.getTopics()
  },
  methods: {
    //选择
    handleSelectionChange(val) {
      this.albumFileIds = val
    },
    // 批量删除
    batchDelete() {
      let deleteData = this.albumFileIds.map((item) => {
        const pdfId = item.pdfId
        return pdfId
      })
      console.log(deleteData)
      this.deleteApi(deleteData)
    },

    deleteApi(data) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await delPDFInfos(data)
          // console.log(res);
          res.code == 0 &&
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          const totalPage = Math.ceil(
            (this.total - 1) / this.queryParams.pageSize
          )
          this.queryParams.page =
            this.queryParams.page > totalPage
              ? totalPage
              : this.queryParams.page
          this.queryParams.page =
            this.queryParams.page < 1 ? 1 : this.queryParams.page
          this.getPDFInfoList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    sc(value) {
      this.formData.tags = value.replace(/，/gi, ',')
      console.log(this.formData.tags)
    },

    change123(val) {
      this.formData.upDateMsg = val
      console.log(this.formData.upDateMsg)
    },

    async getTopics() {
      let { data } = await getTopics({
        type: 2,
        page: 1,
        pageSize: 100
      })
      this.res = data
    },

    handlePreview(file) {
      if (this.formData.pdfFid) {
        window.open(
          'http://file.innopinenut.com/' + this.formData.pdfFid,
          '_blank'
        )
      } else {
        this.$message.warning('暂不支持预览')
      }
    },

    addfl() {
      this.$router.push({
        path: '/managementpdf'
      })
    },
    // addf2() {
    //   this.$router.push({
    //     path: '/pdftopics'
    //   })
    // },
    btnOK1() {
      this.$refs.pForm
        .validate()
        .then(() => {
          return addType(this.fdata)
        })
        .then(() => {
          //  提示消息
          this.$message.success('添加分类成功')
          this.getList()
          this.imageUrl1 = ''
          this.dialog = false
        })
    },
    btnCancel1() {
      this.formData = {}
      this.fdata = {}
      this.imageUrl1 = ''
      this.$refs.pForm.resetFields()
      this.$refs.up1.clearFiles()
      this.dialog = false
    },

    async getList() {
      let res = await getTypes({
        typeGroup: 'PDF'
      })
      console.log(res)

      this.options = res.data
    },

    async selectChange2(val) {
      this.queryParams.typeId = val
      let { data, count } = await getPDFInfoList(this.queryParams)
      this.list = data
      this.total = count
    },
    selectChange(value) {
      this.formData.typeId = value
      console.log(this.formData.typeId)
    },
    selectChange1(value) {
      this.formData.topicIdArr = value
      console.log(this.formData.topicIdArr)
    },
    upchange(value) {
      this.formData.upAction = value
      console.log(this.formData.upAction)
    },

    async setPDFInfoStatus(row) {
      if (row.pdfToImgStatus == 3) {
        let id = row.pdfId
        let res = await setPDFInfoStatus({ pdfId: id })
        console.log(res.code)
        res.code == 0 &&
          this.$message({
            type: 'success',
            message: '设置成功'
          })
        //  this.getPDFInfoList();
      } else {
        this.$message({
          type: 'warning',
          message: 'PDF正在转换中,请稍后'
        })
        // this.getPDFInfoList();
      }
      this.getPDFInfoList()
    },
    async setPDFInfoRecStatus(row) {
      let id = row.pdfId
      let res = await setPDFInfoRecStatus({ pdfId: id })
      res.code == 0 &&
        this.$message({
          type: 'success',
          message: '设置成功'
        })
      this.getPDFInfoList()
    },
    //获取列表
    async getPDFInfoList() {
      let { data, count } = await getPDFInfoList(this.queryParams)
      this.list = data
      this.total = count
      console.log(this.list)
      this.loading = false
    },
    //查询
    async searchgetPDFInfoList() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        title: this.searchtitle
      }

      let { data, count } = await getPDFInfoList(this.queryParams)
      this.list = data
      this.total = count
    },
    // 上传方法
    handleAvatarSuccess(res, file) {
      console.log('上传成功', res, file)
      this.formData.pdfFid = res.key
    },
    async beforeAvatarUpload(file) {
      // console.log(file);
      const isLt30M = file.size / 1024 / 1024 < 30
      if (!isLt30M) {
        this.$message.error('上传PDF的大小不能超过 30M!')
      } else {
        let { data } = await getUploadToken()
        // console.log(data);
        if (data == '' || data == null) {
          this.message.error('上传文件时获取Token失败！')
          return
        }
        this.postData.token = data.token
        this.postData.key = data.fid

      }
    },

    // 上传pdf封面
    handleAvatarSuccess1(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl1 = "http://file.innopinenut.com/" + res.key;
      this.formData.cover = res.key;
      this.$message.success('上传成功')
    },
    async beforeAvatarUpload1(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData1.token = data.token;
      this.postData1.key = data.fid;
    },
    // 删除图片
    handleRemove1(file) {
      if (file.response.key === this.formData.cover) {
        this.formData.cover = ''
      }
    },
    handleRemove(file) {
      console.log(file);
      let key = file.url.split('/')[3]
      if (key === this.formData.cover) {
        this.formData.cover = ''
      }
    },

    // 打开添加的窗口
    addSchoolInfo() {
      // this.imageUrl=null
      this.title = '添加PDF信息'
      this.showDialog = true
      this.formData = {
        pdfFid: '',
        typeId: '',
        title: '',
        brief: '',
        topicIdArr: [],
        tags: '',
        upAction: 0,
        upDateMsg: '',
        cover: ''
      }
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
      this.fileList = []
      console.log(val)
      this.title = '编辑PDF信息'
      if (val.cover) {
        this.formData.cover = val.cover; //头像
        this.fileList.push({
          url: "http://file.innopinenut.com/" + val.cover
        });
      }
      this.formData = {
        sort: val.sort,
        pdfId: val.pdfId,
        // pdfFid:val.pdfFid,
        title: val.title,
        typeId: val.typeId,
        brief: val.brief,
        topicIdArr: val.topicIdArr,
        tags: val.tags,
        upAction: val.upAction,
        upDateMsg: uptime(val.upTime),
        cover: val.cover
      }
      this.showDialog = true
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.pdfId) {
            return modifyPDFInfo(this.formData)
          } else {
            return addPDFInfo(this.formData)
          }
        })
        .then(() => {
          //  提示消息
          if (this.formData.pdfId) {
            this.$message.success('编辑成功')
          } else {
            this.$message.success('新增成功')
            this.$refs.up.clearFiles()
          }

          this.getPDFInfoList()
          this.imageUrl1 = ''
          this.showDialog = false
        })
    },

    //弹窗取消函数
    btnCancel() {
      this.formData = {}
      this.fdata = {}
      this.imageUrl1 = ''
      this.$refs.perForm.resetFields()
      this.$refs.up1.clearFiles()
      this.showDialog = false

    },
    //删除信息
    async delPDFInfo(row) {
      try {
        await this.$confirm('确定要删除该PDF吗')
        await delPDFInfo({ pdfId: row.pdfId })
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        )
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page
        this.getPDFInfoList()

        this.$message.success('删除成功')
      } catch (error) {
        console.log(error)
      }
    },

    getView(row) {
      // console.log(row);
      this.$router.push({
        path: "browse",
        query: {
          id: row.pdfId,
        },
      });
    },

    viewShare(row) {
      // console.log(row);
      this.$router.push({
        path: "share",
        query: {
          id: row.pdfId,
        },
      });
    }
  }
}

function uptime(nS) {
  let date = new Date(nS)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let h = date.getHours()
  let m = date.getMinutes()
  h = h < 10 ? '0' + h : h
  m = m < 10 ? '0' + m : m

  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  date = year + '-' + month + '-' + day + ' ' + h + ':' + m
  // 2018-10-09
  return date
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>